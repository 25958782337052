import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employeeAttendanceStatus'
})
export class EmployeeAttendanceStatusPipe implements PipeTransform {

  transform(value: string): any {
    switch (value) {
      case "PRESENCE_REQUESTED":
        return "Prijavljeno prisustvo"
      case "PRESENCE_APPROVED":
        return "Prisustvo odobreno"
      case "CHECK_OUT_REQUESTED":
        return "Prijavljena odjava"
      case "ABSENT":
        return "Odsutan" 
      case "ABSENCE_NOTED":
        return "Prijavljeno odsustvo"
      case "PRESENT":
        return "Prisutan"
    }
  }
}
