<div mat-dialog-content>
  <p>Odaberite koju vrstu štampe želite:</p>
  <mat-radio-group [(ngModel)]="selectAllByFilter" aria-labelledby="example-radio-group-label"
    class="example-radio-group">
    <mat-radio-button class="radio-button" [value]="false">
      Štampaj <strong>SAMO SELEKTOVANE</strong> poružbine<br />
      <div *ngIf="selectAllByFilter == false">Trenutno odabrano <strong>{{totalSelected}}</strong> od {{totalItemFound}}
      </div>
    </mat-radio-button>
    <mat-radio-button class="radio-button" [value]="true">
      Štampaj <strong>SVE</strong> poružbine prema zadatom filteru
    </mat-radio-button>
  </mat-radio-group>
  <div *ngIf="selectAllByFilter && selectedFilter" style="padding-bottom: 20px;">
    <div *ngIf="selectedFilter.queryString">
      <strong>Query String:</strong> {{selectedFilter.queryString}}
    </div>
    <div *ngIf="selectedFilter.dates.startDate">
      <strong>Od datuma:</strong> {{selectedFilter.dates.startDate | date: "dd.MM.yyyy, hh:mm a"}}
    </div>
    <div *ngIf="selectedFilter.dates.endDate">
      <strong>Do datuma:</strong> {{selectedFilter.dates.endDate | date: "dd.MM.yyyy, hh:mm a"}}
    </div>
    <div *ngIf="selectedFilter.orderStatus">
      <strong>Sa statusom:</strong> {{selectedFilter.orderStatus | orderStatus}}
    </div>
    <div *ngIf="selectedFilter.deliveryVehicle">
      <strong>Vozilo:</strong> {{selectedFilter.deliveryVehicle}}
    </div>
  </div>
  <div class="vertical-buttons">
    <button mat-stroked-button (click)="selectOption('order-details')">Detalji poružbina</button>
    <button mat-stroked-button (click)="selectOption('dispatch-list')">Lista za dostavu</button>
    <button mat-stroked-button (click)="selectOption('dispatch-note')">Otpremnice</button>
    <button mat-stroked-button (click)="selectOption('orders-articles-quantities')">List artikala</button>
  </div>
</div>
<div mat-dialog-actions class="action-buttons">
  <button mat-stroked-button color="warn" (click)="onCancel()">Odustani</button>
</div>