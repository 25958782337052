import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { TypesenseService } from "src/app/core/services/typesense.service";
import { environment } from "src/environments/environment";
import { SearchParams, SearchResponse } from "typesense/lib/Typesense/Documents";

export class InboundOrderTypesenseResponse {
  found: number;
  out_of: number;
  currentPage: number;
  totalPages: number;
  results: InboundOrderTS[]

  constructor(found: number, currentPage: number, out_of: number, itemPerPage: number, customersTS: InboundOrderTS[]) {
    this.out_of = out_of;
    this.found = found;
    this.currentPage = currentPage;
    this.results = customersTS

    this.totalPages = Math.ceil(found / itemPerPage)
  }
}

export class InboundOrderTS {
  id: string;
  orgId: string;
  createdAt: Date;
  lastModifiedAt: Date;
  requestedDeliveryDate: Date;
  statusCode: string;
  customerId: string;
  customerName: string;
  supplierDeliveryVehicle: string;

  constructor(obj?: any) {
    this.id = obj && obj.id || "",
      this.orgId = obj && obj.orgId || "",
      this.createdAt = obj && new Date(obj.createdAt * 1000) || new Date(),
      this.lastModifiedAt = obj && new Date(obj.lastModifiedAt * 1000) || new Date(),
      this.requestedDeliveryDate = obj && new Date(obj.requestedDeliveryDate * 1000) || new Date(),
      this.statusCode = obj && obj["status.code"] || "",
      this.customerId = obj && obj["customer.id"] || "",
      this.customerName = obj && obj["customer.name"] || "",
      this.supplierDeliveryVehicle = obj && obj["supplier.deliveryVehicle"] || ""
  }
}

@Injectable({
  providedIn: 'root'
})
export class InboundOrdersTypesense {

  protected path = `${environment.production ? 'prod' : 'stage'}_organization_inbound_orders`

  constructor(private typesense: TypesenseService) { }

  search(search: SearchParams): Observable<InboundOrderTypesenseResponse> {
    // console.log("hitovi nexr:", search)
    // search.per_page = 100 //HACK
    // console.log(this.path)
    const searchSubject = new Subject<InboundOrderTypesenseResponse>()
    this.typesense.orgClient.collections(this.path).documents().search(search).then((response: SearchResponse<{}>) => {
      if (!response.hits) {
        searchSubject.next(new InboundOrderTypesenseResponse(0, 0, 0, 0, []))
        return
      }
      // searchSubject.next(response.hits.map((elem: any) => new InboundOrderTS(elem.document)))

      var searchHits: InboundOrderTS[] = [];
      response.hits.map((elem: any) => searchHits.push(new InboundOrderTS(elem.document)))
      searchSubject.next(new InboundOrderTypesenseResponse(response.found, response.page, response.out_of, search.per_page ?? 20, searchHits));
      searchSubject.complete()
    })
    return searchSubject.asObservable()
  }
}
