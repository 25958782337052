import { Component, Input, Output } from '@angular/core';
import { Article } from 'src/app/core/models/article/article';
import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { EventEmitter } from '@angular/core';
import { ArticleReservations } from 'src/app/core/models/article/articleReservations';
import { Observable, Subject, debounceTime } from 'rxjs';
import { ArticlesStateService } from '../../states/articles/articles-state.service';

@Component({
  selector: 'app-shared-pricelist',
  templateUrl: './shared-pricelist.component.html',
  styleUrls: ['./shared-pricelist.component.sass']
})
export class SharedPricelistComponent {

  private searchSubject = new Subject<string>();

  // @Input() articles: Article[] = []
  // @Input() reservations: ArticleReservations | null = null
  @Output() searchArticles = new EventEmitter()

  articles$: Observable<Article[]> = this.articlesStateService.getArticles()//.pipe(tap(result => console.log(result)))

  query: string = ''
  articlesSearchParams: SearchParams = {
    'q': '*',
    'query_by': 'searchName, name, code',
    'per_page': 20,
    'page': 1
  }

  constructor(private articlesStateService: ArticlesStateService) {
    this.articlesStateService.loadArticlesFromTypesense(this.articlesSearchParams)
    this.searchSubject.pipe(
      debounceTime(500) // Adjust time in milliseconds according to your needs
    ).subscribe(value => this.handleSearch());
  }

  searchParams: SearchParams = {
    'q': '*',
    'query_by': 'name, searchName, code',
    'filter_by': 'isInactive:=false',
    'per_page': 20,
    'page': 1
  }

  getNextPage() {
    this.searchParams.page! += 1;
    this.searchArticles.emit(this.searchParams)
  }

  onSearchChange(query: string) {
    this.searchSubject.next(query);
  }

  fetchArticles() {

  }

  handleSearch() {
    if (this.query.length > 2) {
      this.searchParams = { ...this.searchParams, 'q': this.query, 'page': 1 }
      this.searchArticles.emit(this.searchParams)
    }
  }
}
