import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public titleSubject = new BehaviorSubject<string>(environment.appName)

  public getTitle(): Observable<string> {
    return this.titleSubject.asObservable()
  }
  public setTitle(newTitle: string) {
    this.titleSubject.next(newTitle)
  }

  private history: string[] = [];

  constructor(
    private router: Router,
  ) { }

  public startSaveHistory(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const top = this.history[this.history.length - 1]
        const newUrl = event.urlAfterRedirects
        if (newUrl != top) {
          this.history.push(newUrl)

        }
      }
    })
  }

  public getHistory(): string[] {
    return this.history;
  }

  public goBack(): void {

    if (this.history.length > 0) {
      this.history.pop();
      const previousUrl = this.history.pop() || "/";
      this.router.navigateByUrl(previousUrl);
    } else {
      this.router.navigateByUrl('/')
    }
  }

  public goSpecificRoute(urlPath: string): void {
    this.router.navigateByUrl(urlPath);
  }
}
