<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Beleška</mat-label>
        <input matInput [(ngModel)]="note" type="text">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Otkazi</button>
    <button mat-button (click)="save()" cdkFocusInitial>Dodaj</button>
</div>