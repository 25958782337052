<div mat-dialog-content>
    <mat-radio-group [(ngModel)]="selectAllByFilter" aria-labelledby="example-radio-group-label"
        class="example-radio-group">
        <mat-radio-button class="radio-button" [value]="false">
            Izvezi <strong>SAMO SELEKTOVANE</strong> poružbine<br />
            <div *ngIf="selectAllByFilter == false">Trenutno odabrano <strong>{{totalSelected}}</strong> od
                ukupno {{totalItemFound}}
            </div>
        </mat-radio-button>
        <mat-radio-button class="radio-button" [value]="true">
            Izvezi sve poružbine prema zadatom filteru
        </mat-radio-button>
    </mat-radio-group>
    <div *ngIf="selectAllByFilter && selectedFilter">
        <div *ngIf="selectedFilter.queryString">
            <strong>Query String:</strong> {{selectedFilter.queryString}}
        </div>
        <div *ngIf="selectedFilter.dates.startDate">
            <strong>Od datuma:</strong> {{selectedFilter.dates.startDate | date: "dd.MM.yyyy, hh:mm a"}}
        </div>
        <div *ngIf="selectedFilter.dates.endDate">
            <strong>Do datuma:</strong> {{selectedFilter.dates.endDate | date: "dd.MM.yyyy, hh:mm a"}}
        </div>
        <div *ngIf="selectedFilter.orderStatus">
            <strong>Sa statusom:</strong> {{selectedFilter.orderStatus | orderStatus}}
        </div>
        <div *ngIf="selectedFilter.deliveryVehicle">
            <strong>Vozilo:</strong> {{selectedFilter.deliveryVehicle}}
        </div>
    </div>
</div>
<div mat-dialog-actions class="action-buttons">
    <button mat-button color="primary" (click)="selectOption('export')">Potvrdi</button>
    <button mat-button color="warn" (click)="onCancel()">Odustani</button>
</div>