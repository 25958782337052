import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserService } from 'src/app/auth/services/user.service';
import { Article } from 'src/app/core/models/article/article';
import { ArticleReservations } from 'src/app/core/models/article/articleReservations';
import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { ArticlesActions } from './articles.action';
import { ArticlesState } from './articles.state';
import { ArticlesTS, ArticlesTypesense, ArticlesTypesenseResponse } from './articles.typesense';

@Injectable({
  providedIn: 'root'
})
export class ArticlesStateService {

  constructor(
    private store: Store,
    private userService: UserService,
    private typesense: ArticlesTypesense
  ) { }

  searchArticles(searchParams: SearchParams) {
    const user = this.userService.getOrgUser()

    // if (searchParams.filter_by) {
    //   searchParams.filter_by = `orgId:=${user.orgId} && isInactive:=false && ${searchParams.filter_by}`;
    // } else {
    //   searchParams.filter_by = `orgId:=${user.orgId} && isInactive:=false`;
    // }
    // Define the base filter with orgId and isInactive conditions
    const baseFilter = `orgId:=${user.orgId}`;

    // Append baseFilter to existing filter_by conditionally
    if (!searchParams.filter_by) {
      searchParams.filter_by = baseFilter;
    } else {
      searchParams.filter_by = `${baseFilter} && ${searchParams.filter_by}`;
    }
    console.log("pretrazuje artikle")
    this.store.dispatch(new ArticlesActions.SearchArticles(searchParams))
  }

  loadArticlesFromTypesense(searchParams: SearchParams) {
    const user = this.userService.getOrgUser()

    // if (searchParams.filter_by) {
    //   searchParams.filter_by = `orgId:=${user.orgId} && isInactive:=false && ${searchParams.filter_by}`;
    // } else {
    //   searchParams.filter_by = `orgId:=${user.orgId} && isInactive:=false`;     
    // }

    // Initialize base filter with orgId and isInactive condition
    let baseFilter = `orgId:=${user.orgId}`;

    // Check and append the baseFilter to existing filters conditionally
    if (!searchParams.filter_by) {
      searchParams.filter_by = baseFilter;
    } else {
      searchParams.filter_by = `${baseFilter} && ${searchParams.filter_by}`;
    }

    //this.store.dispatch(new ArticlesActions.LoadArticlesFromTypesense(searchParams))
    this.store.dispatch(new ArticlesActions.SearchArticles(searchParams))
  }

  async search(searchParams: SearchParams) {
    const articles: ArticlesTypesenseResponse | undefined = await this.typesense.searchPromise(searchParams)

    this.store.selectOnce(ArticlesState.getArticlesBucket).subscribe((bucket) => {
      const missingArticlesIds = [];
      for (let art of articles!.results) {
        if (!bucket[art.id]) { missingArticlesIds.push(art.id) }
      }
      for (let artId of missingArticlesIds) {
        this.store.dispatch(new ArticlesActions.ConnectArticle(artId))
      }
    })
  }

  private updateArticles(arrayOfArticles: ArticlesTS[]) {
    this.store.selectOnce(ArticlesState.getArticlesBucket).subscribe((bucket) => {
      const missingArticlesIds = [];
      for (let art of arrayOfArticles) {
        if (!bucket[art.id]) { missingArticlesIds.push(art.id) }
      }

      for (let artId of missingArticlesIds) {
        this.store.dispatch(new ArticlesActions.ConnectArticle(artId))
      }
    })
  }

  async searchAsync(searchParams: SearchParams): Promise<ArticlesTypesenseResponse> {
    try {
      const articles: ArticlesTypesenseResponse | undefined = await this.typesense.searchPromise(searchParams)

      this.updateArticles(articles.results)

      return articles

    } catch (error) {
      console.error("doslo je do greske prilikom pretrage oglasa:", error)
      return new ArticlesTypesenseResponse(0, 0, 0, 0, [])
    }
  }

  connectArticle(id: string) {
    this.store.dispatch(new ArticlesActions.ConnectArticle(id))
  }

  getArticles(): Observable<Article[]> {
    return this.store.select(ArticlesState.getArticlesBucket).pipe(
      map((articlesBucket: any) => Object.values(articlesBucket))
    )
  }

  getArticlesOnlyOnce(): Article[] {
    return this.store.selectSnapshot(ArticlesState.getAllArticlesFromStateonlyOnce())
  }


  getTotalFoundedArticles() {
    return this.store.select(ArticlesState.getTotalFoundedArticles)
  }


  getTotalLoadedArticles() {
    return this.store.select(ArticlesState.getTotalLoadedArticles)
  }



  getArticle(id: string) {
    console.log(id)
    return this.store.select(ArticlesState.getArticle).pipe(
      map((filterFn: any) => filterFn(id)),
    )
  }

  getArticlesReservations(): Observable<ArticleReservations> {
    return this.store.select(ArticlesState.getArticlesReservations)
  }

  getSearchedArticles(): Observable<Article[]> {
    return this.store.select(ArticlesState.getSearchedArticles)
  }
}
