// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  swEnable: true,
  firebase: {
    apiKey: "AIzaSyDpSZgMhM6dJIOqbCpFXfo2G1GjMmJkTMU", //stage
    authDomain: "mprocesses-fb-stage.firebaseapp.com",
    projectId: "mprocesses-fb-stage",
    storageBucket: "mprocesses-fb-stage.appspot.com",
    messagingSenderId: "739250452179",
    appId: "1:739250452179:web:2e959343ca8c0d7ac44626",
    measurementId: "G-EECRGQHDLW",
  },
  vapidKey: "BNJVVZWVGHKg2peZcbnQdLoJB2hG-T837aPcEHkM6xYkh3_-ucxHzxTPo3zeCTMu_4GiT_7SfsloraTWIgL-640",
  useEmulators: false,
  typesenseHost: 'typesense.prod.tefter.com',
  typesensePort: 443,
  typesenseProtocol: 'https',
  baseUrl: 'https://business.stage.tefter.com',
  appName: "Tefter",
  isMultOrgEnabled: true,
  singleOrgId: "",
  procurementSingleSupplierId: "",
  verifyOtpUrl: "https://verifyotpv2-rrwra574wq-ew.a.run.app", //stage
  sendOtpCodeUrl: "https://sendotpv2-rrwra574wq-ew.a.run.app" //stage
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
