import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Note } from 'src/app/core/models/order/note'; // refactor position of this note

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.sass']
})
export class NoteComponent {

  note!: string;
  
    constructor(
      public dialogRef: MatDialogRef<NoteComponent>,
      @Inject(MAT_DIALOG_DATA) public data: { title: string, notes: Array<Note> }) {
  
    }
  
    close() {
      this.dialogRef.close()
    }
  
    save() {
      if (this.note) {
        let result: Note = new Note(
          {
            createdByUserId: "",
            categoryId: "comment",
            createdAt: new Date(),
            message: this.note
          }
        )
        this.dialogRef.close(result)
      }
    }

}
