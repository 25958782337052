<div class="cards-container">
    <mat-accordion>
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Pretraga artikala
                </mat-panel-title>
                <mat-panel-description>
                    <div>{{query}}</div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field style="width: 100%;">
                <mat-label>Potraži artikle</mat-label>
                <input matInput placeholder="Unesite naziv" type="text" [(ngModel)]="query"
                    (ngModelChange)="onSearchChange(query)">
            </mat-form-field>


        </mat-expansion-panel>
    </mat-accordion>
</div>

<div *ngIf="{articles: articles$ | async} as articlesData">
    <div *ngIf="articlesData && articlesData.articles && articlesData.articles.length">
        <div *ngFor="let article of articlesData.articles | articleSearchPipe:query">
            <app-shared-article-details [article]="article"></app-shared-article-details>
        </div>
    </div>
    <button mat-raised-button color="primary" (click)="getNextPage()" style="margin-bottom: 80px;">Učitaj još</button>
</div>

<!-- <div *ngIf="articles && articles.length;else noArticles">
    <div class="customer-container">
        <div *ngFor="let article of articles | articleSearchPipe:query">
            <app-shared-article-details [article]="article"></app-shared-article-details>
        </div>
    </div>
    <button mat-raised-button color="primary" (click)="getNextPage()" style="margin-bottom: 80px;">Učitaj još</button>
</div> -->

<!-- <ng-template #noArticles>
    <p>No articles available.</p>
    <button mat-raised-button color="primary" (click)="fetchArticles()">
        Učitaj artikle
    </button>
</ng-template> -->