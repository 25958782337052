import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterWhOrder } from 'src/app/core/models/warehouse/filterWhOrder';

@Component({
  selector: 'app-print-options',
  templateUrl: './print-options.component.html',
  styleUrls: ['./print-options.component.sass']
})
export class PrintOptionsComponent {

  selectAllByFilter = false;

  selectedFilter: FilterWhOrder | undefined
  totalItemFound: number = 0
  totalSelected: number = 0

  constructor(
    public dialogRef: MatDialogRef<PrintOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      filter: FilterWhOrder,
      totalItemFound: number,
      totalSelected: number
    }
  ) {
    this.selectedFilter = data.filter
    this.totalItemFound = data.totalItemFound
    this.totalSelected = data.totalSelected

  }

  selectOption(option: string): void {
    // this.dialogRef.close({ option, selectAllByFilter: this.selectAllByFilter });
    this.dialogRef.close({ option, selectAllByFilter: this.selectAllByFilter });
  }

  onCancel(): void {
    this.dialogRef.close(); // Close the dialog without returning any data
  }
}
