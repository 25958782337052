import { DoBootstrap, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscribable, Subscription, of, throwError } from 'rxjs';
import { from, Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { OrgUser, UserFS } from 'src/app/core/models/user/user';
import { NO_ORGANIZATION, NO_TEFTER_ACCOUNT, NO_USER, NO_USER_ID, PENDING_TO_JOIN, UserService } from './user.service';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { GlobalActions } from 'src/app/core/actions/global.actions';
import { Store } from '@ngxs/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'firebase/auth';
import { Auth, getAuth, RecaptchaVerifier } from '@angular/fire/auth';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userAF: any; // user from firebase
  tmpUserData: any;
  private loggedInSubject = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedInSubject.asObservable();

  private idTokenSubject = new BehaviorSubject<any>("TOKEN_PENDING");
  idToken$ = this.idTokenSubject.asObservable();

  confirmationResult: any;

  private auth: Auth
  private recaptchaVerifier: RecaptchaVerifier | null = null

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router,
    private store: Store,
    private userService: UserService,
    private organizatinoServcie: OrganizationService,
    private http: HttpClient,
  ) {
    this.auth = getAuth()

    this.afAuth.onAuthStateChanged(async (user: any) => {

      if (user) {

        const fbUserObject = await user.getIdTokenResult()
        var selectedOrgId: string

        const arrayOrg: string[] = fbUserObject.claims['orgIds']
        this.idTokenSubject.next(fbUserObject)

        this.userAF = user;
        // console.log("this.userAF.id !=", fbUserObject.claims.user_id)

        if (arrayOrg != undefined && arrayOrg.length > 0) {

          const currentOrgFromLocalStorage = localStorage.getItem("orgId")

          if (currentOrgFromLocalStorage) {
            selectedOrgId = currentOrgFromLocalStorage
          } else {
            const userFromDb = await this.userService.getUserByUID(fbUserObject.claims.user_id)
            selectedOrgId = arrayOrg[0]

            if (userFromDb.orgId != undefined && userFromDb.orgId != "") {
              selectedOrgId = userFromDb.orgId
            }

          }

          await this.userService.updateCurrentUser(this.userAF.uid.toString(), selectedOrgId, arrayOrg)

          await this.organizatinoServcie.setUserOganization(selectedOrgId)

        } else {

          const dbUser = await this.userService.getUserByUID(fbUserObject.claims.user_id)

          if (dbUser.id == NO_USER_ID) {
            //user has firebase account but not tefter account...          
            console.log('user has firebase account but not tefter account...')
            const _toUserFS = new UserFS()
            _toUserFS.id = fbUserObject.claims.user_id
            _toUserFS.phoneNumber = fbUserObject.claims.phone_number
            _toUserFS.registrationStatus = NO_TEFTER_ACCOUNT
            this.userAF = _toUserFS
            this.userService.setCurrentUser(this.userAF)


            this.router.navigate(['/account/register-user']);
            return
          } else {

            var _foundedUser = new UserFS(dbUser)

            if (_foundedUser.registrationStatus == PENDING_TO_JOIN) {
              this.router.navigate(['/account/info']);
              return
            }
            _foundedUser.registrationStatus = NO_ORGANIZATION
            this.userAF = _foundedUser
            this.userService.setCurrentUser(_foundedUser)
            this.router.navigate(['/account/register-organization']);
            return
          }

        }

        if (this.router.url == "/login") {
          this.router.navigate(['app']);
        }
      } else {
        // console.log("PROMENA KORISNIKA: NEMA KORISNIKA");
        // // this.userAF = NO_USER
        this.userService.setCurrentUser(NO_USER)
        localStorage.removeItem('user');
        localStorage.removeItem('orgId');

        this.router.navigate(['login']);
      }
    })
  }

  public processNewUser(user: UserFS): UserFS {
    if (user == undefined || !user.id) {
      user = new UserFS({
        id: this.userAF?.uid,
        phoneNumber: this.userAF?.phoneNumber
      })
    }
    return user;
  }

  private userSubject = new BehaviorSubject<any>({});
  user$ = this.userSubject.asObservable();

  isLoggedIn(): boolean {
    return this.userAF ? true : false;
  }

  // getFirebaseUser(): User {
  getFirebaseUser(): UserFS {
    return this.userAF;
  }

  getUserFromLocalStorage() {
    return JSON.parse(localStorage.getItem('user') || "");
  }

  // private sendOtpCodeUrlProd = "https://sendotpv2-dayj4pmm6q-ew.a.run.app"
  // private sendOtpCodeUrlStage = "https://sendotpv2-rrwra574wq-ew.a.run.app" //stage


  sendOtpCode(phoneNumber: string, recaptchaToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(environment.sendOtpCodeUrl, { phoneNumber, recaptchaToken }, { headers })
  }

  // private verifyOtpUrlProd = "https://verifyotpv2-dayj4pmm6q-ew.a.run.app"
  // private verifyOtpUrlStage = "https://verifyotpv2-rrwra574wq-ew.a.run.app"

  verifyOtp(phoneNumber: string, otp: string): Observable<any> {
    return this.http.post(environment.verifyOtpUrl, { phoneNumber, otp });
  }

  sendLoginCode(phoneNumber: string, appVerifier: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afAuth.signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          this.confirmationResult = confirmationResult;
          resolve();
        }).catch((error) => {
          // Error; SMS not sent
          // ...          
          reject(error)
        });

    });
  }

  verifyLoginCode(smsCode: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.confirmationResult.confirm(smsCode)
        .then(() => {
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  logIn(email: any, password: any): Observable<any> {
    return from(this.afAuth.signInWithEmailAndPassword(email, password))
      .pipe(
        map(
          result => {
            return result;
          }),
        catchError(
          error => {
            return throwError(error);
          })
      );
  };


  async clearAllStatesAndSignout() {
    this.store.dispatch(new GlobalActions.ClearAll()).subscribe(() => {
      this.logOut();
    });
  }

  async logOut() {
    localStorage.removeItem('user')
    localStorage.removeItem('orgId')
    this.userService.setCurrentUser(NO_USER);
    this.userService.setOrgUser(new OrgUser())
    this.userAF = undefined
    // this.router.navigate(['login'])
    await this.afAuth.signOut();

    window.location.reload();
  }

}


