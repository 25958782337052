import { ERPIntegrationStatus, IERPIntegrationStatus } from "../integration/erpIntegrationStatus"
import { IInboundOrderSupplier, InboundOrderSupplier } from "./supplier/inboundOrderSupplier"
import { IOrderCustomer, OrderCustomer } from "./customer/orderCustomer"
import { IOrderArticle, OrderArticle } from "./orderArticle"
import { IOrderStatus, OrderStatus } from "./orderStatus"
import { INote, Note } from "./note"
import { InboundOrderType } from "./inboundOrderType"
// import { appVersion } from "../../../core/services/version.service"
import { DocumentMetadata, IDocumentMetadata } from "../documentMetadata"
import { VersionService } from "../../services/version.service"

export interface IInboundOrder {
  _metadata: IDocumentMetadata;
  articles: Array<IOrderArticle>;
  createdAt: Date;
  createdByUserId?: string;
  createdByUserName?: string;
  createdByPhoneNumber: string;
  customer: IOrderCustomer;
  deliverAsSoonAsPossible: boolean;
  ERPIntegrationStatus: IERPIntegrationStatus;
  ERPOrderReferenceCode: string;
  id: string;
  isMarkedAsArchived: boolean;
  labels: Array<string>;
  lastModifiedAt: Date;
  lastModifiedByUserId: string;
  lastModifiedByUserName?: string;
  notes: Array<INote>;
  number: string;
  requestedDeliveryDate: Date;
  status: IOrderStatus;
  supplier: IInboundOrderSupplier;
  type: InboundOrderType;
  version: number;
  appVersion: string;
}


export class InboundOrder {
  _metadata: DocumentMetadata;
  createdAt: Date;
  createdByUserId: string;
  createdByUserName: string;
  createdByPhoneNumber: string;
  customer: OrderCustomer;
  deliverAsSoonAsPossible: boolean;
  ERPIntegrationStatus: ERPIntegrationStatus;
  ERPOrderReferenceCode: string;
  id: string;
  isMarkedAsArchived: boolean;
  labels: Array<string>;
  lastModifiedAt: Date;
  lastModifiedByUserId: string;
  lastModifiedByUserName: string;
  notes: Array<Note>;
  number: string;
  articles: Array<OrderArticle>;
  status: OrderStatus;
  requestedDeliveryDate: Date;
  supplier: InboundOrderSupplier;
  type: InboundOrderType;
  version: number;
  appVersion: string;
  isSplitEnabled: boolean;
  splitAttribute: string;
  splitValues: string[];

  constructor(obj?: any) {
    this._metadata = obj && obj._metadata && new DocumentMetadata(obj._metadata) || new DocumentMetadata();
    this.articles = obj && obj.articles && obj.articles.map((x: any) => new OrderArticle(x)) || []
    if (obj && obj.products && obj.products.length > 0 && !obj.articles) {
      this.articles = obj && obj.products && obj.products.map((x: any) => new OrderArticle(x)) || [];
    }
    this.createdAt = obj && obj.createdAt && (typeof obj.createdAt.toDate == 'function' &&
      obj.createdAt.toDate() || obj.createdAt) || new Date();
    this.createdByUserId = obj && obj.createdByUserId || "";
    this.createdByUserName = obj && obj.createdByUserName || "";
    this.createdByPhoneNumber = obj && obj.createdByPhoneNumber || "";
    this.customer = obj && new OrderCustomer(obj.customer) || new OrderCustomer();
    this.deliverAsSoonAsPossible = obj && obj.deliverAsSoonAsPossible || false;
    this.ERPIntegrationStatus = obj && new ERPIntegrationStatus(obj.ERPIntegrationStatus) || new ERPIntegrationStatus();
    this.ERPOrderReferenceCode = obj && obj.ERPOrderReferenceCode || "";
    this.id = obj && obj.id || "";
    this.isMarkedAsArchived = obj && obj.isMarkedAsArchived || false;
    this.labels = obj && obj.labels || [""];
    this.lastModifiedAt = obj && obj.lastModifiedAt && (typeof obj.lastModifiedAt.toDate == 'function' &&
      obj.lastModifiedAt.toDate() || obj.lastModifiedAt) || new Date();
    this.lastModifiedByUserId = obj && obj.lastModifiedByUserId || "";
    this.lastModifiedByUserName = obj && obj.lastModifiedByUserName || "";
    this.notes = obj && obj.notes && obj.notes.filter((n: any) => n.categoryId != 'deliveryVehicle').map((x: any) => new Note(x)) || [];

    this.number = obj && obj.number || "";
    this.status = obj && new OrderStatus(obj.status) || new OrderStatus();
    this.requestedDeliveryDate = obj && obj.requestedDeliveryDate && (typeof obj.requestedDeliveryDate.toDate == 'function' &&
      obj.requestedDeliveryDate.toDate() || obj.requestedDeliveryDate) || new Date((new Date(new Date().setHours(0, 0, 0, 0))).setDate((new Date(new Date().setHours(0, 0, 0, 0))).getDate() + 1));
    this.supplier = obj && obj.supplier && new InboundOrderSupplier(obj.supplier) || new InboundOrderSupplier();
    /* if (!this.supplier.deliveryVehicle) {
      if( obj && obj.notes && obj.notes.filter((n: any) => n.categoryId == 'deliveryVehicle').length > 0) {
      let deliveryVehicle = obj.notes.filter((n: any) => n.categoryId == 'deliveryVehicle')[0].message
      this.supplier.deliveryVehicle = deliveryVehicle
    }} */
    this.type = obj && obj.type || InboundOrderType.ORDER;
    this.version = obj && obj.version || 0;
    //this.appVersion = obj && obj.appVersion || appVersion;
    this.appVersion = obj && obj.appVersion || VersionService.instance.getVersion();

    this.isSplitEnabled = obj && obj.isSplitEnabled || false;
    this.splitAttribute = obj && obj.splitAttribute || ""
    this.splitValues = obj && obj.splitValues || [];

  }

  toFirestore(): any {
    return {
      _metadata: this._metadata.toFirestore(),
      articles: this.articles.map((article: OrderArticle) => article.toFirestore()),
      createdAt: this.createdAt,
      createdByUserId: this.createdByUserId,
      createdByUserName: this.createdByUserName,
      createdByPhoneNumber: this.createdByPhoneNumber,
      customer: this.customer.toFirestore(),
      deliverAsSoonAsPossible: this.deliverAsSoonAsPossible,
      // ERPIntegrationStatus: this.ERPIntegrationStatus.toFirestore(),
      ERPOrderReferenceCode: this.ERPOrderReferenceCode,
      id: this.id,
      isMarkedAsArchived: this.isMarkedAsArchived,
      labels: this.labels,
      lastModifiedAt: this.lastModifiedAt,
      lastModifiedByUserId: this.lastModifiedByUserId,
      lastModifiedByUserName: this.lastModifiedByUserName,
      notes: this.notes.map((note: Note) => note.toFirestore()),
      number: this.number,
      requestedDeliveryDate: this.requestedDeliveryDate,
      status: this.status.toFirestore(),
      supplier: this.supplier.toFirestore(),
      type: this.type,
      version: this.version + 1,
      appVersion: VersionService.instance.getVersion(),
      isSplitEnabled: this.isSplitEnabled,
      splitAttribute: this.splitAttribute,
      splitValues: this.splitValues
    };
  }

  fromFirestore(snapshot: any, options: any): any {
    const data = snapshot.data(options);
    return new InboundOrder(data);
  }

}
